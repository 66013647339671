import aiSolutions from './ai-solutions';
import digitalEvolution from './digital-evolution';
import e2dTransformation from './e2d-transformation';

const posts = [
    aiSolutions,
    digitalEvolution,
    e2dTransformation
];

export default posts;